import axios from "axios";

const PHOENIXPLORER_API = axios.create({
  baseURL: "https://phoenixplorer.com:3001/api",
});
const humanReadableSupply = (totalSupply, decimals) => {
    return parseFloat(totalSupply) / Math.pow(10, decimals);
  };
  
const getToken = async (contractOrName) => {
  console.log("Contract or name in getToken:", contractOrName);

  // Check if contractOrName is undefined or empty
  if (!contractOrName || contractOrName.trim().length === 0) {
    console.error("contractOrName is undefined or empty.");
    const input = prompt("Please enter a valid contract address or token name:");
    contractOrName = input.trim();
  }

  const params = {
    module: "token",
    action: "getToken",
    contractaddress: contractOrName,
  };

  console.log("params:", params);

  try {
    const response = await PHOENIXPLORER_API.get(`/`, { params });
    console.log("response:", response.data);

    if (response.data.status === "1") {
      return response.data.result;
    } else {
      console.error("Error while fetching token data:", response.data.message);
      return { error: { msg: response.data.message } };
    }
  } catch (error) {
    console.error(error);
    return { error: { msg: "unknown from frontend" } };
  }
};

let ApiToken = {
  getToken,
};

export default ApiToken;
